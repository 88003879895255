// Scroll Event
//
const scrollMenuVisibleClass = 'gnav-active';
let ticking = false;

// 高さを取得する要素
const topGlobalHeroWrapper = document.getElementById('topGlobalHeroWrapper');
let scrollEventPosition = topGlobalHeroWrapper.offsetHeight;

window.addEventListener('resize', function(){
  scrollEventPosition = topGlobalHeroWrapper.offsetHeight;
});

function func()
{
  if (!ticking) {
    requestAnimationFrame(function() {
      ticking = false;
      // GREAT SHORI
      if( scrollEventPosition <= document.documentElement.scrollTop ){

        document.body.classList.add(scrollMenuVisibleClass);
        return;
      }
      document.body.classList.remove(scrollMenuVisibleClass);
    });
    ticking = true;
  }
}

// loading
const loadingElm = document.getElementById('loading');

window.onload = function() {
  loadingElm.classList.add('loaded');
};



document.addEventListener('DOMContentLoaded', function(){
  // スクロールでメニューが表示する
  window.addEventListener('scroll', func, { passive: true });
});



// SLIDER
const swiper = new Swiper('.swiper-container', {
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  speed: 2000,
  //spaceBetween: 0,
  //slidesPerView: 1,
  //centeredSlides: true,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  //direction: 'horizontal',
  loop: true
});
